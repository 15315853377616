import React from 'react';
import { connect } from 'react-redux';
import siteMeta from "../data/siteMeta.json";
import { getSiteLang } from "../redux/selector";

export const Contact = ({ siteLang }) => {
    return (
        <section className="w-screen py-24">
            <div className={`
                w-screen grid grid-cols-6 gap-4
                pt-20 tablet:pt-10 lg:pt-0
            `}>
                <div className="col-start-2 col-span-4 mb-5">
                    <span
                        className={`
                        uppercase font-extrabold text-white text-xl tablet:text-3xl lg:text-4xl
                `}
                    >{siteMeta.Contact.title[siteLang]}</span>
                </div>
                <div
                    className={`
                        lg:col-start-2 lg:col-span-1 bg-[#0005]
                        col-start-2 col-span-4
                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 p-3
                        flex flex-col text-md lg:text-xs
                    `}
                >
                    <span
                        className={`font-extrabold text-md`}
                    >
                        {siteMeta.Contact["contact-1"].title[siteLang]}
                    </span>
                    <a
                        href="https://maps.app.goo.gl/g57B2eiCaV2GpXaf8"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="flex flex-col">
                            <span
                                className={`mt-2 text-slate-300`}
                            >
                                {siteMeta.Contact["contact-1"].address["line-1"][siteLang]}
                            </span>
                            <span
                                className={`text-slate-300`}
                            >
                                {siteMeta.Contact["contact-1"].address["line-2"][siteLang]}
                            </span>
                            <span
                                className={`text-slate-300`}
                            >
                                {siteMeta.Contact["contact-1"].address["line-3"][siteLang]}
                            </span>
                        </div>
                    </a>
                    <span
                        className={`mt-2`}
                    >
                        <i className="fa-regular fa-phone mr-4 font-extrabold text-lg"></i>
                        <a href={siteMeta.Contact["contact-1"]["phone-number"].href}>
                            {siteMeta.Contact["contact-1"]["phone-number"].text}
                        </a>
                    </span>
                    <span
                        className={`underline`}
                    >
                        <i className="fa-regular fa-envelope mr-4 font-extrabold text-lg"></i>
                        <a href={siteMeta.Contact.socials.email.href}>
                            {siteMeta.Contact.socials.email.text}
                        </a>
                    </span>
                    <span
                        className={``}
                    >
                        <i className="fa-brands fa-whatsapp mr-4 font-extrabold text-lg"></i>
                        <a
                            href={siteMeta.Contact.socials.whatsapp.href}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {siteMeta.Contact.socials.whatsapp.text}
                        </a>
                    </span>
                </div>
                <div
                    className={`
                        lg:col-start-3 lg:col-span-1 bg-[#0005]
                        col-start-2 col-span-4
                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 p-3
                        flex flex-col text-md lg:text-xs
                    `}
                >
                    <span
                        className={`font-extrabold text-md`}
                    >
                        {siteMeta.Contact["contact-2"].title[siteLang]}
                    </span>
                    <a
                        href="https://maps.app.goo.gl/2R2FkUpsueqGpDLE6"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="flex flex-col">
                            <span
                                className={`mt-2 text-slate-300`}
                            >
                                {siteMeta.Contact["contact-2"].address["line-1"][siteLang]}
                            </span>
                            <span
                                className={`text-slate-300`}
                            >
                                {siteMeta.Contact["contact-2"].address["line-2"][siteLang]}
                            </span>
                            <span
                                className={`text-slate-300`}
                            >
                                {siteMeta.Contact["contact-2"].address["line-3"][siteLang]}
                            </span>
                        </div>
                    </a>
                    <span
                        className={`mt-2`}
                    >
                        <i className="fa-regular fa-phone mr-4 font-extrabold text-lg"></i>
                        <a href={siteMeta.Contact["contact-2"]["phone-number"].href}>
                            {siteMeta.Contact["contact-2"]["phone-number"].text}
                        </a>
                    </span>
                    <span
                        className={`underline`}
                    >
                        <i className="fa-regular fa-envelope mr-4 font-extrabold text-lg"></i>
                        <a href={siteMeta.Contact.socials.email.href}>
                            {siteMeta.Contact.socials.email.text}
                        </a>
                    </span>
                    <span
                        className={``}
                    >
                        <i className="fa-brands fa-whatsapp mr-4 font-extrabold text-lg"></i>
                        <a
                            href={siteMeta.Contact.socials.whatsapp.href}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {siteMeta.Contact.socials.whatsapp.text}
                        </a>
                    </span>
                </div>
                <div
                    className={`
                        lg:col-start-4 lg:col-span-1 bg-[#0005]
                        col-start-2 col-span-4
                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 p-3
                        flex flex-col text-md lg:text-xs
                    `}
                >
                <span
                    className={`font-extrabold text-md`}
                >
                    {siteMeta.Contact["contact-3"].title[siteLang]}
                </span>
                <a
                    href="https://maps.app.goo.gl/pmoBunzicupkySH47"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="flex flex-col">
                        <span
                            className={`mt-2 text-slate-300`}
                        >
                            {siteMeta.Contact["contact-3"].address["line-1"][siteLang]}
                        </span>
                        <span
                            className={`text-slate-300`}
                        >
                            {siteMeta.Contact["contact-3"].address["line-2"][siteLang]}
                        </span>
                        <span
                            className={`text-slate-300`}
                        >
                            {siteMeta.Contact["contact-3"].address["line-3"][siteLang]}
                        </span>
                    </div>
                </a>
                <span
                    className={`mt-2`}
                >
                    <i className="fa-regular fa-phone mr-4 font-extrabold text-lg"></i>
                    <a href={siteMeta.Contact["contact-3"]["phone-number"].href}>
                        {siteMeta.Contact["contact-3"]["phone-number"].text}
                    </a>
                </span>
                <span
                    className={`underline`}
                >
                    <i className="fa-regular fa-envelope mr-4 font-extrabold text-lg"></i>
                    <a href={siteMeta.Contact.socials.email.href}>
                        {siteMeta.Contact.socials.email.text}
                    </a>
                </span>
                <span
                    className={``}
                >
                    <i className="fa-brands fa-whatsapp mr-4 font-extrabold text-lg"></i>
                    <a
                        href={siteMeta.Contact.socials.whatsapp.href}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {siteMeta.Contact.socials.whatsapp.text}
                    </a>
                </span>
            </div>
                <div
                    className={`
                        lg:col-start-5 lg:col-span-1 bg-[#0005]
                        col-start-2 col-span-4
                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 p-3
                        flex flex-col text-md lg:text-xs
                    `}
                >
                <span
                    className={`font-extrabold text-md`}
                >
                    {siteMeta.Contact["contact-4"].title[siteLang]}
                </span>
                <a
                    href="https://maps.app.goo.gl/8Cu69CRGVWLgpDsXA"
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="flex flex-col">
                        <span
                            className={`mt-2 text-slate-300`}
                        >
                            {siteMeta.Contact["contact-4"].address["line-1"][siteLang]}
                        </span>
                        <span
                            className={`text-slate-300`}
                        >
                            {siteMeta.Contact["contact-4"].address["line-2"][siteLang]}
                        </span>
                        <span
                            className={`text-slate-300`}
                        >
                            {siteMeta.Contact["contact-4"].address["line-3"][siteLang]}
                        </span>
                    </div>
                </a>
                <span
                    className={`mt-2`}
                >
                    <i className="fa-regular fa-phone mr-4 font-extrabold text-lg"></i>
                    <a href={siteMeta.Contact["contact-4"]["phone-number"].href}>
                        {siteMeta.Contact["contact-4"]["phone-number"].text}
                    </a>
                </span>
                <span
                    className={`underline`}
                >
                    <i className="fa-regular fa-envelope mr-4 font-extrabold text-lg"></i>
                    <a href={siteMeta.Contact.socials.email.href}>
                        {siteMeta.Contact.socials.email.text}
                    </a>
                </span>
                <span
                    className={``}
                >
                    <i className="fa-brands fa-weixin mr-4 font-extrabold text-lg"></i>
                    <a
                        href={siteMeta.Contact.socials.whatsapp.href}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {siteMeta.Contact.socials.whatsapp.text}
                    </a>
                </span>
            </div>

            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    siteLang: getSiteLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);