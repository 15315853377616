import React, { useState } from 'react';
import { connect } from 'react-redux';
import InvestmentModal from "../components/InvestmentModal";
import siteMeta from "../data/siteMeta.json";
import { getSiteLang } from "../redux/selector";

export const Options = ({ siteLang }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    return (
        <section className="w-screen py-24">
            <InvestmentModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={modalData}
            />
            <div className={`
                w-screen grid grid-cols-6 gap-4 justify-around content-center items-center
                tablet:pt-10 lg:pt-0
            `}>
                <div className="col-start-2 col-span-4 mb-5">
                    <span
                        className={`
                            uppercase font-extrabold text-white text-xl tablet:text-3xl lg:text-4xl
                    `}
                    >{siteMeta.Options.title[siteLang]}</span>
                </div>
                <div className="col-start-2 col-span-5 grid grid-cols-6 lg:grid-cols-5 gap-4">
                    <div
                        className={`
                            bg-[#0005] ring-1 ring-offset-1 ring-slate-100 text-slate-100 px-5 py-1
                            lg:col-start-1 col-span-5 lg:col-span-2 grid grid-rows-6
                        `}
                    >
                        <div className="row-span-1 flex items-center content-center">
                            <p className="uppercase font-extrabold underline underline-offset-4 text-sm lg:text-lg">
                                {siteMeta.Options["option-1"].title[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-4 lg:text-lg">
                            <p className="mt-2">
                                {siteMeta.Options["option-1"].teaser[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-1 flex items-center content-center">
                            <div className={`w-full flex justify-end`}>
                                <button
                                    className={`
                                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 font-bold
                                        py-1 px-3
                                        hover:bg-[#fffa] hover:text-[#000a]
                                    `}
                                    onClick={() => {
                                        setModalData(siteMeta.Options["option-1"]["modal-key"]["en"]);
                                        setIsOpen(true);
                                    }}
                                >
                                    {siteMeta.Options["option-1"].button[siteLang]}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`
                            bg-[#0005] ring-1 ring-offset-1 ring-slate-100 text-slate-100 px-5 py-1
                            lg:col-start-3 col-span-5 lg:col-span-2 grid grid-rows-6
                        `}
                    >
                        <div className="row-span-1 flex items-center content-center">
                            <p className="uppercase font-extrabold underline underline-offset-4 text-sm lg:text-lg">
                                {siteMeta.Options["option-2"].title[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-4 lg:text-lg">
                            <p className="mt-2">
                                {siteMeta.Options["option-2"].teaser[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-1 flex items-center content-center">
                            <div className={`w-full flex justify-end`}>
                                <button
                                    className={`
                                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 font-bold
                                        py-1 px-3
                                        hover:bg-[#fffa] hover:text-[#000a]
                                    `}
                                    onClick={() => {
                                        setModalData(siteMeta.Options["option-2"]["modal-key"]["en"]);
                                        setIsOpen(true);
                                    }}
                                >
                                    {siteMeta.Options["option-2"].button[siteLang]}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`
                            bg-[#0005] ring-1 ring-offset-1 ring-slate-100 text-slate-100 px-5 py-1
                            lg:col-start-1 col-span-5 lg:col-span-2 grid grid-rows-6
                        `}
                    >
                        <div className="row-span-1 flex items-center content-center">
                            <p className="uppercase font-extrabold underline underline-offset-4 text-sm lg:text-lg">
                                {siteMeta.Options["option-3"].title[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-4 lg:text-lg">
                            <p className="mt-2">
                                {siteMeta.Options["option-3"].teaser[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-1 flex items-center content-center">
                            <div className={`w-full flex justify-end`}>
                                <button
                                    className={`
                                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 font-bold
                                        py-1 px-3
                                        hover:bg-[#fffa] hover:text-[#000a]
                                    `}
                                    onClick={() => {
                                        setModalData(siteMeta.Options["option-3"]["modal-key"]["en"]);
                                        setIsOpen(true);
                                    }}
                                >
                                    {siteMeta.Options["option-3"].button[siteLang]}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`
                            bg-[#0005] ring-1 ring-offset-1 ring-slate-100 text-slate-100 px-5 py-1
                            lg:col-start-3 col-span-5 lg:col-span-2 grid grid-rows-6
                        `}
                    >
                        <div className="row-span-1 flex items-center content-center">
                            <p className="uppercase font-extrabold underline underline-offset-4 text-sm lg:text-lg">
                                {siteMeta.Options["option-4"].title[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-4 lg:text-lg">
                            <p className="mt-2">
                                {siteMeta.Options["option-4"].teaser[siteLang]}
                            </p>
                        </div>
                        <div className="row-span-1 flex items-center content-center">
                            <div className={`w-full flex justify-end`}>
                                <button
                                    className={`
                                        ring-1 ring-offset-1 ring-slate-100 text-slate-100 font-bold
                                        py-1 px-3
                                        hover:bg-[#fffa] hover:text-[#000a]
                                    `}
                                    onClick={() => {
                                        setModalData(siteMeta.Options["option-4"]["modal-key"]["en"]);
                                        setIsOpen(true);
                                    }}
                                >
                                    {siteMeta.Options["option-4"].button[siteLang]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    siteLang: getSiteLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Options);