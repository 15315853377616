import data from "./investment-details.json";
import costs from "./investment-costs.json";
import others from "./investment-others.json";

let keyIndex = 0;

export const investmentDataDetails = (title, lang = "en") => {
    switch (title) {
        case "National Economic Fund": {
            return (
                <div className="p-6 border border-gray-300 mb-6">
                    <p className="mb-4">
                        {data["National Economic Fund"]["paragraph-1"][lang]}
                    </p>
                    <p className="mb-4">
                        {data["National Economic Fund"]["paragraph-2"][lang]}
                    </p>
                    <p className="mb-4">
                        {data["National Economic Fund"]["paragraph-3"][lang]}
                    </p>
                </div>
            );
        }
        case "National Action Bond": {
            return (
                <div className='p-6 border border-gray-300 mb-6'>
                    <p className='mb-4'>
                        {data["National Action Bond"]["paragraph-1"][lang]}
                    </p>
                    <p className='mb-4'>
                        {data["National Action Bond"]["paragraph-2"][lang]}
                    </p>
                    <p className='mb-4'>
                        {data["National Action Bond"]["paragraph-3"][lang]}
                    </p>
                    <p className='mb-4'>
                        {data["National Action Bond"]["paragraph-4"].title[lang]}
                        <ul className='list-disc list-inside ml-5'>
                            {
                                data["National Action Bond"]["paragraph-4"].list
                                    .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                            }
                        </ul>
                    </p>
                    <p className='mb-4'>
                        {data["National Action Bond"]["paragraph-5"][lang]}
                    </p>
                </div>
            );
        }
        case "Real Estate Investment": {
            return (
                <div className='p-6 border border-gray-300 mb-6'>
                    <p className='mb-4'>
                        {data["Real Estate Investment"]["paragraph-1"][lang]}
                    </p>
                    <h3 className='text-lg font-bold mb-2'>{data["Real Estate Investment"]["paragraph-2"][lang]}</h3>
                    <ul className='list-disc list-inside'>
                        {
                            data["Real Estate Investment"]["paragraph-3"].list
                                .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                        }
                    </ul>
                </div>
            );
        } case "Enterprise Project Investment": {
            return (
                <div className="p-6 border border-gray-300 mb-6">
                    <p className="mb-4">
                        {data["Enterprise Project Investment"]["paragraph-1"][lang]}
                    </p>
                    <p className="mb-4">
                        {data["Enterprise Project Investment"]["paragraph-2"][lang]}
                    </p>
                    <p className="mb-4">
                        {data["Enterprise Project Investment"]["paragraph-3"][lang]}
                    </p>
                </div>
            );
        }
        default:
            return null;
    }
};

export const investmentDataCosts = (title, lang) => {
    switch (title) {
        case "National Economic Fund":
            return (
                <div className='p-6 border border-gray-300 mb-6'>
                    <h2 className='text-xl font-bold mb-4'>{costs["National Economic Fund"].title[lang]}</h2>
                    <ul className='list-disc list-inside'>
                        {
                            costs["National Economic Fund"].list
                                .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                        }
                    </ul>
                </div>
            );

        case "National Action Bond":
            return (
                <div className='p-6 border border-gray-300 mb-6'>
                    <h2 className='text-xl font-bold mb-4'>{costs["National Action Bond"].title[lang]}</h2>
                    <ul className='list-disc list-inside'>
                        {
                            costs["National Action Bond"].list
                                .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                        }
                    </ul>
                </div>
            );

        case "Real Estate Investment":
            return (
                <div className='p-6 border border-gray-300 mb-6'>
                    <h2 className='text-xl font-bold mb-4'>{costs["Real Estate Investment"].title[lang]}</h2>
                    <ul className='list-disc list-inside'>
                        {
                            costs["Real Estate Investment"].list
                                .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                        }
                        <ul className='list-disc list-inside ml-5'>
                            {
                                costs["Real Estate Investment"]["list-two"]
                                    .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                            }
                        </ul>
                        {
                            costs["Real Estate Investment"]["list-three"]
                                .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                        }
                    </ul>
                </div>
            );

        case "Enterprise Project Investment":
            return (
                <div className='p-6 border border-gray-300 mb-6'>
                    <h2 className='text-xl font-bold mb-4'>{costs["Enterprise Project Investment"].title[lang]}</h2>
                    <ul className='list-disc list-inside'>
                        {
                            costs["Enterprise Project Investment"]["list"]
                                .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                        }
                        <ul className='list-disc list-inside ml-5'>
                            {
                                costs["Enterprise Project Investment"]["list-two"]
                                    .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                            }
                        </ul>
                        {
                            costs["Enterprise Project Investment"]["list-three"]
                                .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                        }
                        <ul className='list-disc list-inside ml-5'>
                            {
                                costs["Enterprise Project Investment"]["list-four"]
                                    .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                            }
                        </ul>
                    </ul>
                </div>
            );

        default:
            return null;
    }
};

export const investmentDataOthers = (lang) => {
    return (
        <div className='p-6 border border-gray-300 mb-6'>
            <h2 className='text-xl font-bold mb-4'>{others.title[lang]}</h2>

            <h3 className='text-lg font-bold mb-2'>{others["subtitle-1"][lang]}</h3>
            <p className='mb-4'>
                {others["paragraph-1"][lang]}
            </p>
            <ul className='list-disc list-inside mb-4'>
                {
                    others["list-1"]
                        .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                }
            </ul>

            <h3 className='text-lg font-bold mb-2'>{others["subtitle-2"][lang]}</h3>
            <p className='mb-4'>
                {others["paragraph-2"][lang]}
            </p>
            <ul className='list-disc list-inside mb-4'>
                {
                    others["list-2"]
                        .map(r => <li className='mb-2' key={keyIndex++}>{r[lang]}</li>)
                }
            </ul>
            <p className='mb-4'>{others["paragraph-3"][lang]}</p>
            <p className='mb-4'>
                {others["paragraph-4"][lang]}
            </p>
            <p className='mb-4'>
                {others["paragraph-5"][lang]}
            </p>
        </div>
    );
};