import React from 'react';
import { connect } from 'react-redux';

export const CountryList = ({ list, continent }) => {
    const generateCountryList = (countryList) => {
        return (
            <div>
                {countryList.map((country, index) => (
                    <div key={index} className="country-item flex items-center content-center">
                        <span className={`${country.className} text-xl mr-2`}></span>
                        <span className="country-name">{country.country}</span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="flex flex-col mt-5">
            <div className="text-xl font-bold flex items-center content-center mb-2">{continent}</div>
            {generateCountryList(list)}
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CountryList);