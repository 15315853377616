export const LOADING_START = "LOADING_START";
export const loadingStart = () => ({
    type: LOADING_START
});

export const LOADING_END = "LOADING_END";
export const loadingEnd = () => ({
    type: LOADING_END
});

// export const SWITCH_SITE_LANGUAGE = "SWITCH_SITE_LANGUAGE";
// export const switchSiteLang = (language) => ({
//     type: SWITCH_SITE_LANGUAGE,
//     payload: {
//         language: language
//     }
// });

export const SWITCH_SITE_LANGUAGE = "SWITCH_SITE_LANGUAGE";
export const switchSiteLang = (language) => ({
    type: SWITCH_SITE_LANGUAGE,
    payload: {
        language: language
    }
});