// CountryListModal.jsx
import { Dialog } from '@headlessui/react';
import visaData from "../data/countries.json";
import CountryList from "./CountryList";
import "../../node_modules/flag-icons/css/flag-icons.min.css";

const CountryListModal = ({ isOpen, setIsOpen }) => {
    return (
        <>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="fixed inset-0 flex items-center justify-center z-50 overflow-hidden"
            >
                <div
                    className={`bg-black bg-opacity-20 fixed inset-0`}
                    onClick={() => setIsOpen(false)}
                ></div> {/* Optional backdrop */}
                <Dialog.Panel
                    className={`
                        bg-black bg-opacity-80 relative w-[90%] h-[90%] m-auto rounded-lg
                        text-slate-100 overflow-y-scroll p-10
                        ring-1 ring-offset-1 ring-slate-100
                    `}
                >
                    <Dialog.Title className="text-3xl font-extrabold">St. Lucia Visa-Free Countries</Dialog.Title>
                    <button
                        onClick={() => setIsOpen(false)}
                        className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200"
                    >
                        X
                    </button>
                    <div
                        className={`
                            grid grid-cols-3 my-10
                        `}
                    >
                        <div className="flex flex-col">
                            <CountryList
                                continent="Americas"
                                list={visaData.Americas}
                            />
                            <CountryList
                                continent="Caribbean"
                                list={visaData.Caribbean}
                            />
                            <CountryList
                                continent="Middle East"
                                list={visaData["Middle East"]}
                            />
                        </div>
                        <div className="flex flex-col">
                            <CountryList
                                continent="Africa"
                                list={visaData.Africa}
                            />
                            <CountryList
                                continent="Asia"
                                list={visaData.Asia}
                            />
                        </div>
                        <div className="flex flex-col">
                            <CountryList
                                continent="Europe"
                                list={visaData.Europe}
                            />
                            <CountryList
                                continent="Oceania"
                                list={visaData.Oceania}
                            />
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </>
    );
}

export default CountryListModal;
