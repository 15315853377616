import { LOADING_END, LOADING_START, SWITCH_SITE_LANGUAGE } from "./actions";

const INITIAL_STATE = {
    isLoaded: false,
    isLoading: false,
    siteData: {},
    siteLang: "en"
};

export const appData = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOADING_START:
            return { ...state, isLoaded: false, isLoading: true };

        case LOADING_END:
            return { ...state, isLoaded: true, isLoading: false };

        case SWITCH_SITE_LANGUAGE:
            const { language } = payload;
            return { ...state, siteLang: language };

        default: return state;
    }
};