import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appData } from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const reducers = { appData };

const rootReducer = combineReducers(reducers);
const persistConfig = { key: "root", storage: storage };

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});
export const persistor = persistStore(store);
