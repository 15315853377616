import React, { useState } from 'react';
import { connect } from 'react-redux';
import siteMeta from "../data/siteMeta.json";
import { getSiteLang } from "../redux/selector";

const TimeLineData = (lang = "en") => ({
    step1: {
        content: (
            <div className="row-span-5 lg:text-lg">
                <p className="mt-3">
                    {siteMeta.Timeline["step-1"].content["paragraph-1"][lang]}
                </p>
                <p className="mt-3">
                    {siteMeta.Timeline["step-1"].content["paragraph-2"][lang]}
                </p>
            </div>
        ),
        title: siteMeta.Timeline["step-1"].title[lang]
    },
    step2: {
        content: (
            <div className="row-span-5 lg:text-lg">
                <p className="mt-3">
                    {siteMeta.Timeline["step-2"].content["paragraph-1"][lang]}
                </p>
            </div>
        ),
        title: siteMeta.Timeline["step-2"].title[lang]
    },
    step3: {
        content: (
            <div className="row-span-5 lg:text-lg">
                <p className="mt-3">
                    {siteMeta.Timeline["step-3"].content["paragraph-1"][lang]}
                </p>
                <p className="mt-3">
                    {siteMeta.Timeline["step-3"].content["paragraph-2"][lang]}
                </p>
            </div>
        ),
        title: siteMeta.Timeline["step-3"].title[lang]
    }
}
);

export const Timeline = ({ siteLang }) => {
    const data = TimeLineData(siteLang);
    const [selectedTime, setSelectedTime] = useState(1);

    return (
        <section className="w-screen">
            <div className={`
                w-screen grid grid-cols-6 gap-4 justify-around content-center items-center
                pt-20 tablet:pt-10 lg:pt-0 lg:h-[400px]
            `}>
                <div className="col-start-2 col-span-4 mb-5">
                    <span
                        className={`
                            uppercase font-extrabold text-white text-xl tablet:text-3xl lg:text-4xl
                    `}
                    >{siteMeta.Timeline.title[siteLang]}</span>
                </div>
                <div className={`
                    col-start-2 col-span-4 h-2 rounded-full
                    bg-gradient-to-r from-[#fff5] to-[#ffff]
                    grid grid-cols-4 items-center content-center
                `}>
                    <div className={`flex justify-end`}>
                        <div
                            className={`
                                h-7 w-7 hover:scale-125 bg-[#fffe] rounded-full
                                ${(selectedTime === 1) ?
                                    "scale-125 ring ring-offset-1 ring-lime-300" :
                                    ""
                                }
                                flex justify-center items-center content-center
                                font-bold
                                hover:cursor-pointer
                            `}
                            onClick={() => setSelectedTime(1)}
                        >1</div>
                    </div>
                    <div className={`flex justify-end`}>
                        <div
                            className={`
                                h-7 w-7 hover:scale-125 bg-[#fffe] rounded-full
                                ${(selectedTime === 2) ?
                                    "scale-125 ring ring-offset-1 ring-lime-300" :
                                    ""
                                }
                                flex justify-center items-center content-center
                                font-bold
                                hover:cursor-pointer
                            `}
                            onClick={() => setSelectedTime(2)}
                        >2</div>
                    </div>
                    <div className={`flex justify-end`}>
                        <div
                            className={`
                                h-7 w-7 hover:scale-125 bg-[#fffe] rounded-full
                                ${(selectedTime === 3) ?
                                    "scale-125 ring ring-offset-1 ring-lime-300" :
                                    ""
                                }
                                flex justify-center items-center content-center
                                font-bold
                                hover:cursor-pointer
                            `}
                            onClick={() => setSelectedTime(3)}
                        >3</div>
                    </div>
                    <div className={`flex justify-end`}>
                        <div
                            className={`
                                h-7 w-7 bg-green-500 scale-150 rounded-full
                                flex items-center content-center justify-center
                                ring ring-offset-1 ring-slate-50
                                text-slate-50 font-extrabold
                            `}
                        >
                            <i className="fa-solid fa-check"></i>
                        </div>
                    </div>
                </div>
                <div className="col-start-2 col-span-4 my-4 grid grid-rows-6 text-slate-100 h-[350px] lg:h-auto">
                    <div className="row-span-1 text-xl font-semibold text-white">
                        {data[`step${selectedTime}`]?.title}
                    </div>
                    {data[`step${selectedTime}`]?.content}
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    siteLang: getSiteLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);