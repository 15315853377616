import { switchSiteLang } from "./actions";

export const setSiteLang = (language) => async dispatch => {
    try {
        if (["ar", "cn", "en", "ru"].includes(language)) {
            dispatch(switchSiteLang(language));
        } else {
            console.error("the language is invalid");
        }
    } catch (error) { dispatch(console.log(error)); }
};