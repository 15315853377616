import React from 'react';
import { connect } from 'react-redux';
import Home from "./sections/Home";
import webBg from "./images/home.webp";
import Contact from "./sections/Contact";
import Timeline from "./sections/Timeline";
import Options from "./sections/Options";
import logo from "./images/logo.png";
import { setSiteLang } from "./redux/thunks";
import { getSiteLang } from "./redux/selector";

export const App = ({ setLanguage, siteLang }) => {
  return (
    <div className="-screen min-h-screen bg-scroll" style={styles.bgImage}>
      <div className={`
        fixed top-1 lg:top-10 left-1 lg:left-10
        h-20 w-20 lg:h-32 lg:w-32 lg:bg-[#fffa] rounded-full
        flex justify-center items-center content-center
        overflow-x-hidden
      `}>
        <img src={logo} className={`
          w-16 lg:w-24
        `} alt="" />
      </div>
      <div className="fixed top-5 w-screen flex justify-end pr-5 tablet:pr-0 tablet:justify-center content-center items-center">
        <div className="flex items-center h-10 rounded-full content-center justify-center bg-[#000c]">
        <button
          className={`
            text-white mx-2 px-2 ${
              (siteLang === "ar") ? "opacity-100 scale-125" : "opacity-50 hover:opacity-75"
            }
          `}
          onClick={() => setLanguage("ar")}
        >
          <span className={`fi fi-sa`}></span>
        </button>
        <button
          className={`
            text-white mx-2 px-2 ${
              (siteLang === "cn") ? "opacity-100 scale-125" : "opacity-50 hover:opacity-75"
            }
          `}
          onClick={() => setLanguage("cn")}
        >
          <span className={`fi fi-cn`}></span>
        </button>
        <button
          className={`
            text-white mx-2 px-2 ${
              (siteLang === "en") ? "opacity-100 scale-125" : "opacity-50 hover:opacity-75"
            }
          `}
          onClick={() => setLanguage("en")}
        >
          <span className={`fi fi-gb`}></span>
        </button>
        <button
          className={`
            text-white mx-2 px-2 ${
              (siteLang === "ru") ? "opacity-100 scale-125" : "opacity-50 hover:opacity-75"
            }
          `}
          onClick={() => setLanguage("ru")}
        >
          <span className={`fi fi-ru`}></span>
        </button>
        </div>
      </div>
      <div className="w-screen min-h-screen bg-[#0002]">
        <Home />
        <Options />
        <Timeline />
        <Contact />
      </div>
      <div className="w-screen fixed bottom-3 flex pr-5 lg:pr-10 justify-end items-center content-center text-xs font-extralight text-slate-800">
        &#169; Blue Islands Ltd. - <a href="https://vydconsulting.org">VYD Consulting</a>
      </div>
    </div>
  );
};

const styles = {
  bgImage: {
    backgroundAttachment: "scroll",
    backgroundImage: `url(${webBg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }
};

const mapStateToProps = (state) => ({
  siteLang: getSiteLang(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(setSiteLang(language))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);