import React, { useState } from 'react';
import { connect } from 'react-redux';
import flag from "../images/flag.png";
import CountryListModal from "../components/CountryListModal";
import siteMeta from "../data/siteMeta.json";
import { getSiteLang } from "../redux/selector";

export const Home = ({ siteLang }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <section className="w-screen min-h-screen overflow-x-hidden py-32">
            <CountryListModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={`
                w-screen min-h-screen flex flex-col justify-around content-center items-center
                pt-20 tablet:pt-10 lg:pt-0
            `}>
                <div className="flex flex-col w-3/4 lg:w-1/2 text-left py-10">
                    <div className="flex items-center content-center">
                        <span
                            className={`
                                uppercase font-extrabold text-white text-4xl tablet:text-5xl lg:text-8xl
                            `}
                        >
                            {siteMeta.Home.title[siteLang]}
                        </span>
                        <img src={flag} className="w-20 ml-3 lg:w-40 lg:ml-5 lg:mt-1" alt="" />
                    </div>
                    <span
                        className={`
                        uppercase font-bold text-white text-[9px] lg:text-xl
                    `}
                    >{siteMeta.Home.slogan[siteLang]}</span>
                    <hr className="font-extrabold mt-2" />
                    <span
                        className={`
                            font-bold text-slate-100 text-xs tablet:text-sm lg:text-lg mt-5
                        `}
                    >
                        {siteMeta.Home.intro[siteLang]}
                    </span>
                    <span
                        className={`
                            font-bold text-slate-100 text-xs tablet:text-sm lg:text-lg mt-5
                        `}
                    >
                        {siteMeta.Home["intro-2"][siteLang]}
                    </span>
                </div>
            </div>
            <div className={`
                w-screen grid grid-cols-6 gap-4 justify-around content-center items-center
                pt-20 tablet:pt-10 lg:pt-0
            `}>
                <div className="col-start-2 col-span-4 mb-5">
                    <span
                        className={`
                            uppercase font-extrabold text-white text-xl tablet:text-3xl lg:text-4xl
                    `}
                    >{siteMeta.Home.subtitle[siteLang]}</span>
                </div>
                <div className="col-start-2 col-span-4 mb-5">
                    <span
                        className={`
                            font-semibold text-slate-100 text-xs tablet:text-sm lg:text-lg mt-1 mb-5
                        `}
                    >
                        {siteMeta.Home["subtitle-intro"][siteLang]}
                        <ul className="my-3">
                            <li className="ml-5">{siteMeta.Home["subtitle-options"]["option-1"][siteLang]}</li>
                            <li className="ml-5">{siteMeta.Home["subtitle-options"]["option-2"][siteLang]}</li>
                            <li className="ml-5">{siteMeta.Home["subtitle-options"]["option-3"][siteLang]}</li>
                            <li className="ml-5">{siteMeta.Home["subtitle-options"]["option-4"][siteLang]}</li>
                        </ul>
                    </span>
                    <span
                        className={`
                            font-semibold text-slate-100 text-xs tablet:text-sm lg:text-lg mt-1 mb-5
                        `}
                    >
                        {siteMeta.Home["subtitle-outro"][siteLang]}
                    </span>
                </div>
                <div
                    className={`
                        lg:col-start-2 lg:col-span-2 text-slate-100
                        col-start-2 col-span-4
                    `}
                >
                    <span className="font-extrabold">
                        <i className="fa-regular fa-clock fa-xl mr-2"></i>
                        <span className="underline underline-offset-4">{
                            siteMeta.Home["info-1"]["title"][siteLang]}
                        </span>
                    </span>
                    <span className="ml-2">
                        {siteMeta.Home["info-1"]["value"][siteLang]}
                    </span>
                </div>
                <div
                    className={`
                        lg:col-start-2 lg:px-10 lg:col-span-3 text-slate-100
                        col-start-2 col-span-4
                    `}
                >
                    <span className="font-extrabold">
                        <i className="fa-solid fa-hourglass-end fa-xl mr-2"></i>
                        <span className="underline underline-offset-4">
                            {siteMeta.Home["info-2"]["title"][siteLang]}
                        </span>
                    </span>
                    <span className="ml-2">
                        {siteMeta.Home["info-2"]["value"][siteLang]}
                    </span>
                </div>
                <div
                    className={`
                        lg:col-start-2 lg:px-20 lg:col-span-3 text-slate-100
                        col-start-2 col-span-4
                    `}
                >
                    <span className="font-extrabold">
                        <i className="fa-solid fa-passport fa-xl mr-2"></i>
                        <span className="underline underline-offset-4">
                            {siteMeta.Home["info-3"]["title"][siteLang]}
                        </span>
                    </span>
                    <span className="ml-2">
                        {siteMeta.Home["info-3"]["value"][siteLang]}
                    </span>
                    <span className="ml-2" id="countryListTrigger">
                        <button onClick={() => setIsOpen(true)}>
                            <i className="fa-regular fa-circle-question fa-beat-fade"></i>
                        </button>
                    </span>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    siteLang: getSiteLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);