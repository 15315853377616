// InvestmentModal.jsx
import { Dialog } from '@headlessui/react';
import { investmentDataCosts, investmentDataDetails, investmentDataOthers } from "../data/investmentData";
import { connect } from "react-redux";
import { getSiteLang } from "../redux/selector";

const InvestmentModal = ({ isOpen, setIsOpen, title, siteLang }) => {
    return (
        <>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="fixed inset-0 flex items-center justify-center z-50 overflow-hidden"
            >
                <div
                    className={`bg-black bg-opacity-20 fixed inset-0`}
                    onClick={() => setIsOpen(false)}
                ></div> {/* Optional backdrop */}
                <Dialog.Panel
                    className={`
                        bg-black bg-opacity-80 relative w-[90%] h-[90%] m-auto rounded-lg
                        text-slate-100 overflow-y-scroll p-10
                        ring-1 ring-offset-1 ring-slate-100
                    `}
                >
                    <Dialog.Title
                        className={`
                            text-lg tablet:text-2xl lg:text-3xl font-extrabold
                            text-center tablet:text-left
                        `}
                    >{title}</Dialog.Title>
                    <button
                        onClick={() => setIsOpen(false)}
                        className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200"
                    >
                        X
                    </button>
                    <div
                        className={`
                            grid grid-flow-row my-10
                        `}
                    >
                        <div>
                            {investmentDataDetails(title, siteLang)}
                        </div>
                        <div>
                            {investmentDataCosts(title, siteLang)}
                        <div>
                        </div>
                            {investmentDataOthers(siteLang)}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    siteLang: getSiteLang(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentModal);
